import logo from './logo.svg';
import './App.css';

// PARTIALS
import MainNav from './components/menu';
import appHeight from './appHeight';
import Footer from './components/footer';

function App() {
  return (
    <>
      <MainNav />
      <Footer />
    </>
  );
}

export default App;
