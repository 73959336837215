import { useState, useRef } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";
import emailjs, { send } from '@emailjs/browser';

// === Pages

    function ContactForm() {

        const [submitted, setSubmitted] = useState(false);
        const form = useRef();

        const sendEmail = (e) => {
            e.preventDefault();
        
            emailjs.sendForm('service_4tqqw3p', 'template_uv9aome', form.current, 'OX9mii6HraXqrCz3G')
              .then((result) => {
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
        };
        
        const handleSubmit = () => {
           setTimeout(() => {
               setSubmitted(true);
           }, 100);
        };

        if(submitted) {
            return (
                <div className="main-content-wrapper">
                    <h2 className="section-title">CONTACT US</h2>
                    <div className="contact-wrapper">
                        <h2 className="contact-response"><em>Thank you for reaching out. <br></br>We will be in touch soon!</em></h2>
                    </div>
                </div>
            );
        };
        
        return (
            <div className="main-content-wrapper">
                <h2 className="section-title">CONTACT US</h2>
                <div className="contact-wrapper">
                    <h1><em>Got a project?</em></h1>
                    <form
                        ref={form}
                        onSubmit={sendEmail}>
                        <div className="contact-container">
                            <div className="input-wrapper">
                                <input 
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    className="contact-input"
                                    required
                                    />
                                <input 
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    className="contact-input"
                                    required
                                    />
                                <input 
                                    type="text"
                                    placeholder="Phone Number"
                                    name="phone_number"
                                    className="contact-input"
                                    required
                                    />
                            </div>
                            <div className="textarea-wrapper">
                                <div className="hexigon-body">
                                    <div className="bl-tr-diagonal-saffron"></div>
                                    <div className="hexigon-middle-saffron"></div>
                                    <div className="tl-br-diagonal-saffron"></div>
                                </div>
                                <textarea
                                    type="text"
                                    placeholder="Tell Us About You"
                                    name="message"
                                    className="textarea-input"
                                    required
                                />
                            </div>
                        </div>
                        <div className="bttn-container">
                            <button 
                                className="contact-bttn"
                                type="submit"
                                value="Send"
                                onClick={handleSubmit}
                                >Join us in the lab
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    
    export default ContactForm;
