import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams } from "react-router-dom";

import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import useFetch from '../useFetch';

// === Pages

    function CaseStudyIntro(props) {

        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/case-studies?filters[slug][$eq]=${id}&populate[Sections][populate]=*&populate[Feature_Image][populate]=*`)
        
        let caseStudy = null;
        let introSection = null;
        let impact = null;
        let sections = [];

        if (data) {


            caseStudy = data.data[0].attributes
            sections = data.data[0].attributes.Sections
            console.log(caseStudy)
            for (let i = 0; i < sections.length; i ++) {
                if (sections[i].__component === "case-study.impact") {
                    impact = sections[i]
                } 
            }

            return (
                <div className="main-content-wrapper">
                    <div className="case-study-info">
                        {/* <h2>{ introSection.Section_Title }</h2> */}
                        {/* <BlocksRenderer content={ introSection.Section_Description } /> */}
                    </div>
                    <div className="intro-case-study-wrapper">
                        <div className="case-study-title"> 
                            <h1>{ caseStudy.Project_Title }</h1>
                            <h2>{ caseStudy.Client }</h2>
                            <h3>{ caseStudy.Campaign_Services }</h3>
                        </div>
                        {/* <img className="intro-case-study-image" src={ `${ caseStudy.Feature_Image.data.attributes.url }`} /> */}
                    </div>
                    <div className="tag">
                        <div className="tag-info">
                            <h3 className="cause">{ caseStudy.Cause }</h3>
                            <div className="tag-analytics">
                                {impact.Analytic.map((analtyic, index) => 
                                    <div className="analytic" key={ index }>
                                        <h3>{ analtyic.Number }</h3>
                                        <h5>{ analtyic.Stat }</h5>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="hexigon-body-cp">
                            <div className="tl-br-diagonal-tag display-none"></div>
                            <div className="hexigon-middle-tag display-none"></div>
                            <div className="bl-tr-diagonal-tag display-none"></div>
                        </div>
                    </div>
                </div>
            );
        }
        
    }
    
    export default CaseStudyIntro;
