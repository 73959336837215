import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";
import useFetch from '../useFetch';

// === Pages

    function LandingCaseStudy() {
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?populate=deep`)

        let landingPage = []
        let caseStudy = []
        let sections = []
        let images = []

        if (data) {
            landingPage = data.data.attributes
            caseStudy = landingPage.case_study.data.attributes
            console.log(caseStudy)
            sections = data.data.attributes.case_study.data.attributes.Sections
            for (let i = 0; i < sections.length; i ++) {
                if (sections[i].__component === "case-study.gallery") {
                    images = sections[i].Gallery.data
                } 
            }

            return (
                <div className="main-content-wrapper">
                    <h2 className="section-title">IN THE LAB</h2>
                    
                    <div className="landing-case-study-wrapper">
                        <h2 className="case-study-intro-line">{ landingPage.CaseStudyIntroLine }</h2>
                        <svg className="intro-line" viewBox="0 0 1336 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_278_556)">
                            <mask id="path-1-inside-1_278_556" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1352 748V506.5V241.5V-5.09131e-05L187.244 -0.000111948L40.6663 -5.09131e-05L-5.99998 44.8656L-6 286.404V461.596L-5.99998 703.134L40.6663 748L187.244 748L1352 748Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1352 748V506.5V241.5V-5.09131e-05L187.244 -0.000111948L40.6663 -5.09131e-05L-5.99998 44.8656L-6 286.404V461.596L-5.99998 703.134L40.6663 748L187.244 748L1352 748Z" fill="#FEFEE4"/>
                            <path d="M1352 748H1357V753L1352 753L1352 748ZM1352 -5.09131e-05L1352 -5.00005L1357 -5.00005V-5.09131e-05H1352ZM187.244 -0.000111948L187.244 -5.00011L187.244 -5.00011L187.244 -0.000111948ZM40.6663 -5.09131e-05L37.201 -3.60444L38.6526 -5.00005L40.6663 -5.00005L40.6663 -5.09131e-05ZM-5.99998 44.8656L-11 44.8656L-11 42.7367L-9.46529 41.2612L-5.99998 44.8656ZM-6 286.404L-11 286.404L-11 286.404L-6 286.404ZM-6 461.596L-11 461.596V461.596H-6ZM-5.99998 703.134L-9.4653 706.739L-11 705.263L-11 703.134L-5.99998 703.134ZM40.6663 748L40.6663 753L38.6526 753L37.201 751.604L40.6663 748ZM187.244 748L187.244 753L187.244 753L187.244 748ZM1357 506.5V748H1347V506.5H1357ZM1347 506.5V241.5H1357V506.5H1347ZM1347 241.5V-5.09131e-05H1357V241.5H1347ZM1352 4.99995L187.244 4.99989L187.244 -5.00011L1352 -5.00005L1352 4.99995ZM187.244 4.99989L40.6663 4.99995L40.6663 -5.00005L187.244 -5.00011L187.244 4.99989ZM44.1316 3.60434L-2.53467 48.47L-9.46529 41.2612L37.201 -3.60444L44.1316 3.60434ZM-0.999982 44.8656L-1 286.404L-11 286.404L-11 44.8656L-0.999982 44.8656ZM-11 461.596V286.404H-1V461.596H-11ZM-11 703.134L-11 461.596L-1 461.596L-0.999982 703.134L-11 703.134ZM37.201 751.604L-9.4653 706.739L-2.53467 699.53L44.1316 744.396L37.201 751.604ZM187.244 753L40.6663 753L40.6663 743L187.244 743L187.244 753ZM1352 753L187.244 753L187.244 743L1352 743L1352 753Z" fill="#E5521C" mask="url(#path-1-inside-1_278_556)"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_278_556">
                            <rect width="1336" height="46" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <h1>{ caseStudy.Project_Title }</h1>
                        <h2>{ caseStudy.Client }</h2>
                        <h3>{ caseStudy.Campaign_Services }</h3>
                        <div className="intro-gallery" id="autoscroll">
                            <div className="inner">
                                {images.map((image) => 
                                    <img className="intro-gallery-image" src={`${image.attributes.url}`} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    
    export default LandingCaseStudy;
