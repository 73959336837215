import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

// === Pages

    function CaseStudyGallery( props ) {

        let section = props.data

        return (

            <div className="main-content-wrapper">
                <div className="case-study-info">
                    <h2>{ section.Section_Title }</h2>
                    <BlocksRenderer content={ section.Section_Description } />
                </div>
                <div className="case-study-gallery-wrapper" >
                    <div className="case-study-gallery" id="autoscroll">
                        <div className="inner mobile-gallery">
                            {section.Gallery.data.map((image) => 
                                <img className="gallery-image" src={ `${ image.attributes.url }` } />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
    
    export default CaseStudyGallery;
