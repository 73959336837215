import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

// === COMPONENTS
import useFetch from '../useFetch';
import Services from '../components/services';
import LandingCaseStudy from '../components/landingCaseStudy';
import ContactForm from '../components/contactForm';

    function LandingPage() {
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?populate[Intro_Video][populate]=*`)

        let landing = []
        let video = []

        if (data) {

            console.log(data)
            landing = data.data.attributes
            video = landing.Intro_Video.data.attributes.url 

            return (
                <div className="page-container">
                    <div className="side-controller">
                        <svg className="cause-stacked" viewBox="0 0 277 838" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M129.664 68.6497V118.695L118.178 130.232H11.4865L0 118.695V11.5368L11.4865 0H118.178L129.664 11.5368V61.5819H68.3505V32.5628C68.3505 31.5686 68.0075 30.7024 67.3312 29.9543C66.655 29.2061 65.8219 28.901 64.832 29.029C63.8422 29.029 63.0091 29.403 62.3328 30.1413C61.6566 30.8894 61.3136 31.7556 61.3136 32.7499V97.4916C61.3136 98.4858 61.5586 99.3225 62.0584 100.002C62.5485 100.681 63.2933 101.085 64.2832 101.213C65.3907 101.459 66.3512 101.213 67.1548 100.464C67.9585 99.7163 68.3603 98.791 68.3603 97.6786V68.6595H129.674L129.664 68.6497Z" fill="#FEFEE4"/>
                            <path d="M275.664 119.253V69.2075L275.674 69.2173L275.664 12.0945L264.178 0.55777H157.486L146 12.0945V119.253L157.486 130.789H264.178L275.664 119.253Z" fill="#F9C12E"/>
                            <path d="M129.664 270.441V220.503L129.674 220.512L129.664 163.512L118.178 152H11.4865L0 163.512L0 270.441L11.4865 281.953H118.178L129.664 270.441Z" fill="#F9C12E"/>
                            <path d="M276.674 738.232H147V608H276.674V637.019H211.837C209.494 637.019 208.318 638.2 208.318 640.553C208.318 642.906 209.494 644.087 211.837 644.087H276.674V669.572H211.837C209.494 669.572 208.318 670.753 208.318 673.106C208.318 675.459 209.494 676.64 211.837 676.64H276.674V738.222V738.232Z" fill="#FEFEE4"/>
                            <path d="M276.664 422.441V372.503L276.674 372.512L276.664 315.512L265.178 304H158.486L147 315.512V422.441L158.486 433.953H265.178L276.664 422.441Z" fill="#F9C12E"/>
                            <path d="M146 282.106V164.437L157.413 153H263.424L274.837 164.437V282.106H213.915V249.835C213.915 248.849 213.613 248.02 212.999 247.346C212.386 246.673 211.646 246.331 210.789 246.331H210.419C209.435 246.331 208.607 246.702 207.935 247.434C207.263 248.176 206.923 249.034 206.923 250.02V282.116H146V282.106ZM207.935 203.911C208.607 204.652 209.435 205.013 210.419 205.013C211.402 205.013 212.23 204.643 212.902 203.911C213.574 203.169 213.915 202.31 213.915 201.325V185.467C213.915 184.481 213.574 183.623 212.902 182.881C212.23 182.139 211.402 181.778 210.419 181.778H210.049C209.192 181.778 208.452 182.12 207.838 182.793C207.224 183.466 206.923 184.296 206.923 185.281V201.325C206.923 202.31 207.263 203.169 207.935 203.911Z" fill="#FEFEE4"/>
                            <path d="M129.674 502.63V573.696L118.187 585.232H0V556.215H64.8369C65.8269 556.215 66.66 555.91 67.3363 555.289C68.0126 554.669 68.3557 553.921 68.3557 553.055V552.681C68.3557 551.687 67.9832 550.85 67.2481 550.171C66.513 549.492 65.6407 549.147 64.6507 549.147H11.4874L0 537.612V466.536L11.4874 455H129.674V484.017H65.0232C64.0332 484.017 63.1707 484.361 62.4257 485.041C61.6808 485.72 61.3182 486.556 61.3182 487.551V487.925C61.3182 488.791 61.6613 489.539 62.3376 490.159C63.0139 490.779 63.847 491.084 64.8369 491.084H118.187L129.674 502.62V502.63Z" fill="#FEFEE4"/>
                            <path d="M275.664 574.695V524.65L275.674 524.66L275.664 467.537L264.178 456H157.486L146 467.537V574.695L157.486 586.232H264.178L275.664 574.695Z" fill="#F9C12E"/>
                            <path d="M129.664 725.441V675.503L129.674 675.512L129.664 618.512L118.178 607H11.4865L0 618.512L0 725.441L11.4865 736.953H118.178L129.664 725.441Z" fill="#F9C12E"/>
                            <path d="M129.664 421.695L118.178 433.232H11.4865L0 421.695V303H61.3136V368.116C61.3136 369.228 61.7154 370.163 62.5191 370.902C63.3227 371.65 64.2832 371.896 65.3907 371.65C66.3806 371.522 67.1156 371.118 67.6154 370.439C68.1055 369.76 68.3603 368.923 68.3603 367.929V303H129.674V421.695H129.664Z" fill="#FEFEE4"/>
                            <path d="M80.9089 763.179L80.9146 796.548L80.9088 796.543V798.967V830.276L74.2363 837H7.6725L1 830.276V763.179L7.6725 756.455H74.2363L80.9089 763.179Z" stroke="#FEFEE4" stroke-width="2"/>
                            <path d="M6.93164 817.66V793.262H17.652V817.569C17.652 818.204 18.2065 818.657 18.761 818.657H76.2443V829.178H18.5762C12.1994 829.178 6.93164 823.918 6.93164 817.66Z" fill="#FEFEE4"/>
                            <path d="M177.165 763.179L177.17 796.548L177.165 796.543V798.967V830.276L170.492 837H103.928L97.2559 830.276V763.179L103.928 756.455H170.492L177.165 763.179Z" stroke="#FEFEE4" stroke-width="2"/>
                            <path d="M113.753 794.522H160.673C166.715 794.522 171.869 799.598 171.869 805.637V829.178H161.561V817.976C161.561 817.364 161.028 816.926 160.495 816.926H113.931C113.398 816.926 112.865 817.364 112.865 817.976V829.178H102.557V805.637C102.557 799.598 107.711 794.522 113.753 794.522ZM112.776 805.724C112.776 806.337 113.309 806.774 113.842 806.774H160.584C161.117 806.774 161.65 806.337 161.65 805.724C161.65 805.112 161.117 804.674 160.584 804.674H113.842C113.309 804.674 112.776 805.112 112.776 805.724Z" fill="#FEFEE4"/>
                            <path d="M273.092 763.179L273.098 796.548L273.092 796.543V798.967V830.276L266.42 837H199.856L193.184 830.276V763.179L199.856 756.455H266.42L273.092 763.179Z" stroke="#FEFEE4" stroke-width="2"/>
                            <path d="M267.167 805.637V805.899C267.167 808.087 266.551 810.187 265.406 811.938C266.551 813.688 267.167 815.701 267.167 817.889V818.151C267.167 824.278 262.236 829.178 256.072 829.178H198.484V794.522H256.072C262.148 794.522 267.167 799.598 267.167 805.637ZM255.984 804.674H208.699V806.774H255.984C256.512 806.774 257.041 806.337 257.041 805.724C257.041 805.112 256.512 804.674 255.984 804.674ZM208.699 819.027H255.984C256.512 819.027 257.041 818.589 257.041 817.976C257.041 817.364 256.512 816.926 255.984 816.926H208.699V819.027Z" fill="#FEFEE4"/>
                        </svg>
                    </div>
                    <div className="main-content-wrapper">
                        <div className="section-title"></div>
                        <div className="video-container">
                            <video id="vid" autoPlay="true" playsinline="true" muted="true" loop="true" className="intro-video">
                                <source type="video/mp4" src={ `${video}` } />
                            </video>
                            <div className="tagline-wrapper">
                                <h1>{landing.Tagline}</h1>
                            </div>  
                        </div>
                    </div>
                        <LandingCaseStudy />
                        <Services />   
                        <ContactForm />               
                </div>
            );
        }
    }
    
    export default LandingPage;