import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

// === Pages
import LandingPage from '../pages/landingPage';
import OurTeam from '../pages/ourTeam';
import CaseStudyView from '../pages/caseStudyView';
import CaseStudyList from '../pages/caseStudyList';

    function MainNav() {
        
        const [open, setOpen] = useState(false)
        const [navHeight, setNavHeight] = useState('0vH')

        const menuToggle = () => {
     
            if (open == true) {
                if (window.innerWidth < 900 ) {
                    setNavHeight('0vH')
                    setOpen(false)
                    document.getElementById(`bar1`).style.top = `0%`;
                    document.getElementById(`bar3`).style.top = `100%`;
                    document.getElementById(`bar1`).style.transform = `rotate(0deg)`;
                    document.getElementById(`bar2`).style.transform = `rotate(0deg)`;
                    document.getElementById(`bar3`).style.transform = `rotate(0deg)`;
                } else {

                setNavHeight('0vH')
                setOpen(false)
                }
            }    

            if (open == false) {
                if (window.innerWidth < 900 ) {
                    console.log(window.innerWidth)
                    setNavHeight('84vH')
                    setOpen(true)
                    document.getElementById(`bar1`).style.top = `44%`;
                    document.getElementById(`bar3`).style.top = `44%`;
                    document.getElementById(`bar1`).style.transform = `rotate(45deg)`;
                    document.getElementById(`bar2`).style.transform = `rotate(45deg)`;
                    document.getElementById(`bar3`).style.transform = `rotate(-45deg)`;
                } else {
                    console.log(window.innerWidth)
                    setNavHeight('84vH')
                    setOpen(true)
                    document.getElementById(`bar1`).style.top = `44%`;
                    document.getElementById(`bar3`).style.top = `44%`;
                    document.getElementById(`bar1`).style.transform = `rotate(45deg)`;
                    document.getElementById(`bar2`).style.transform = `rotate(45deg)`;
                    document.getElementById(`bar3`).style.transform = `rotate(-45deg)`;
                }
            }
            
        }

        const menuAnimationEnter = () => {
     
            if (open == true) {
                document.getElementById(`bar1`).style.transform = `rotate(0deg)`;
                document.getElementById(`bar2`).style.transform = `rotate(0deg)`;
                document.getElementById(`bar3`).style.transform = `rotate(0deg)`;
            }    

            if (open == false) {
                document.getElementById(`bar1`).style.top = `44%`;
                document.getElementById(`bar3`).style.top = `44%`;
            }
            
        }

        const menuAnimationExit = () => {
     
            if (open == true) {
                document.getElementById(`bar1`).style.transform = `rotate(45deg)`;
                document.getElementById(`bar2`).style.transform = `rotate(45deg)`;
                document.getElementById(`bar3`).style.transform = `rotate(-45deg)`;
            }    

            if (open == false) {
                document.getElementById(`bar1`).style.top = `0%`;
                document.getElementById(`bar3`).style.top = `85%`;
            }
            
        }
        
        return (
            <Router>
                <div className="nav-wrapper" > 
                    <div className="menu-height-cntlr" style={{height: navHeight}}>
                        <div className="tab-wrapper">
                            <div className="menu-tab" id="tab-one"></div>
                            <div className="menu-tab" id="tab-two"></div>
                            <div className="menu-tab" id="tab-three"></div>
                            <div className="menu-tab" id="tab-four"></div>
                        </div>
                        <div className="menu-body">
                            <div className="nav-link-wrapper"> 
                                <h2><a href="/our-work">Our Work</a></h2>
                                <h2><a href="/our-team">Our Team</a></h2>
                                <h2><a href="/">Cause Lab</a></h2>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-menu-tab">
                        <div 
                            className="menu-icon"
                            onMouseEnter={() => menuAnimationEnter()}
                            onMouseLeave={() => menuAnimationExit()}
                            onClick={() => menuToggle()}>
                            <div className="menu-bar" id="bar1"></div>
                            <div className="menu-bar" id="bar2"></div>
                            <div className="menu-bar" id="bar3"></div>
                        </div>
                        <svg className="menu-logo" width="241" height="27" viewBox="0 0 241 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.3954 13.9865V24.1826L24.0571 26.533H2.33828L0 24.1826L0 2.35047L2.33828 0L24.0571 0L26.3954 2.35047V12.5465H13.9139V6.63426C13.9139 6.4317 13.8441 6.25522 13.7064 6.1028C13.5688 5.95038 13.3992 5.88821 13.1977 5.91428C12.9962 5.91428 12.8266 5.99049 12.6889 6.1409C12.5513 6.29332 12.4814 6.46981 12.4814 6.67237V19.8627C12.4814 20.0652 12.5313 20.2357 12.6331 20.3741C12.7328 20.5125 12.8845 20.5947 13.086 20.6208C13.3114 20.6709 13.5069 20.6208 13.6705 20.4683C13.8341 20.3159 13.9159 20.1274 13.9159 19.9008V13.9885H26.3974L26.3954 13.9865Z" fill="#E5521C"/>
                            <path d="M30.5762 26.533V2.35047L32.9144 0L54.6333 0L56.9715 2.35047V26.533H44.4901V19.9008C44.4901 19.6982 44.4283 19.5277 44.3026 19.3894C44.1769 19.251 44.0252 19.1808 43.8497 19.1808H43.7739C43.5724 19.1808 43.4028 19.257 43.2651 19.4074C43.1274 19.5598 43.0576 19.7363 43.0576 19.9389V26.535H30.5762V26.533ZM43.2651 10.4628C43.4028 10.6152 43.5724 10.6894 43.7739 10.6894C43.9754 10.6894 44.145 10.6132 44.2826 10.4628C44.4203 10.3104 44.4901 10.1339 44.4901 9.93134V6.67237C44.4901 6.46981 44.4203 6.29332 44.2826 6.1409C44.145 5.98848 43.9754 5.91428 43.7739 5.91428H43.698C43.5225 5.91428 43.3708 5.98447 43.2452 6.12285C43.1195 6.26123 43.0576 6.4317 43.0576 6.63426V9.93134C43.0576 10.1339 43.1274 10.3104 43.2651 10.4628Z" fill="#E5521C"/>
                            <path d="M87.5438 24.1826L85.2055 26.533H63.4867L61.1484 24.1826V0L73.6299 0V13.2665C73.6299 13.4931 73.7117 13.6837 73.8753 13.8341C74.0389 13.9865 74.2344 14.0366 74.4598 13.9865C74.6614 13.9604 74.811 13.8782 74.9127 13.7398C75.0125 13.6014 75.0644 13.431 75.0644 13.2284V0L87.5458 0V24.1826H87.5438Z" fill="#E5521C"/>
                            <path d="M118.081 9.70471V24.1846L115.743 26.535H91.6855V20.6228H104.883C105.085 20.6228 105.254 20.5606 105.392 20.4342C105.53 20.3079 105.599 20.1555 105.599 19.979V19.9028C105.599 19.7002 105.524 19.5298 105.374 19.3914C105.224 19.253 105.047 19.1828 104.845 19.1828H94.0238L91.6855 16.8323V2.35047L94.0238 0L118.081 0V5.91227H104.921C104.72 5.91227 104.544 5.98247 104.392 6.12085C104.241 6.25923 104.167 6.4297 104.167 6.63226V6.70847C104.167 6.88495 104.237 7.03737 104.374 7.16372C104.512 7.29007 104.682 7.35224 104.883 7.35224H115.743L118.081 9.70271V9.70471Z" fill="#E5521C"/>
                            <path d="M148.618 26.533H122.223V0L148.618 0V5.91227H135.42C134.944 5.91227 134.704 6.15294 134.704 6.63226C134.704 7.11157 134.944 7.35224 135.42 7.35224H148.618V12.5445H135.42C134.944 12.5445 134.704 12.7852 134.704 13.2645C134.704 13.7438 134.944 13.9845 135.42 13.9845H148.618V26.531V26.533Z" fill="#E5521C"/>
                            <path d="M178.691 12.7886L178.689 12.7868V13.9865V23.9781L176.64 26.0376H155.333L153.284 23.9781V2.55493L155.333 0.495422H176.64L178.689 2.55496L178.691 12.7886Z" stroke="#E5521C" stroke-width="0.990845"/>
                            <path d="M209.31 12.7886L209.308 12.7868V13.9865V23.9781L207.259 26.0376H185.952L183.904 23.9781V2.55493L185.952 0.495422H207.259L209.308 2.55496L209.31 12.7886Z" stroke="#E5521C" stroke-width="0.990845"/>
                            <path d="M239.929 12.7886L239.927 12.7868V13.9865V23.9781L237.878 26.0376H216.572L214.523 23.9781V2.55493L216.572 0.495422H237.878L239.927 2.55496L239.929 12.7886Z" stroke="#E5521C" stroke-width="0.990845"/>
                            <path d="M154.918 20.1153V12.1616H158.375V20.0858C158.375 20.2927 158.554 20.4406 158.733 20.4406H177.271V23.8704H158.673C156.617 23.8704 154.918 22.1555 154.918 20.1153Z" fill="#E5521C"/>
                            <path d="M188.998 12.6313H204.129C206.078 12.6313 207.74 14.2878 207.74 16.2584V23.941H204.416V20.2853C204.416 20.0854 204.244 19.9426 204.072 19.9426H189.055C188.883 19.9426 188.711 20.0854 188.711 20.2853V23.941H185.387V16.2584C185.387 14.2878 187.049 12.6313 188.998 12.6313ZM188.682 16.287C188.682 16.4869 188.854 16.6297 189.026 16.6297H204.1C204.272 16.6297 204.444 16.4869 204.444 16.287C204.444 16.0871 204.272 15.9443 204.1 15.9443H189.026C188.854 15.9443 188.682 16.0871 188.682 16.287Z" fill="#E5521C"/>
                            <path d="M238.21 16.2584V16.3441C238.21 17.0581 238.011 17.7435 237.641 18.3147C238.011 18.8859 238.21 19.5428 238.21 20.2568V20.3425C238.21 22.3416 236.615 23.941 234.621 23.941H215.99V12.6313H234.621C236.587 12.6313 238.21 14.2878 238.21 16.2584ZM234.592 15.9443H219.295V16.6297H234.592C234.763 16.6297 234.934 16.4869 234.934 16.287C234.934 16.0871 234.763 15.9443 234.592 15.9443ZM219.295 20.6281H234.592C234.763 20.6281 234.934 20.4853 234.934 20.2853C234.934 20.0854 234.763 19.9426 234.592 19.9426H219.295V20.6281Z" fill="#E5521C"/>
                        </svg>
                    </div>
                </div>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/our-team" element={<OurTeam />} />
                    <Route path="/our-work/:id" element={<CaseStudyView />} />
                    <Route path="/our-work/" element={<CaseStudyList />} />
                </Routes>
            </Router>
        );
    }
    
    export default MainNav;