import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import ImpactCard from "../photos/Impact-Card.svg"

// === Pages

    function CaseStudyImpact( props ) {

        let section = props.data

        console.log(section.Analytic)
        return (
            <div className="main-content-wrapper">
                <div className="case-study-info">
                    <h2>{ section.Section_Title }</h2>
                    <BlocksRenderer content={ section.Section_Description } />
                </div>
                <div className="case-study-wrapper">
                    <div className="impact-container">
                        <div className="impact-tag" >
                            {section.Analytic.map((analtyic, index) => 
                                <div className="impact-stat" key={ index }>
                                    <h3>{ analtyic.Number }</h3>
                                    <h5>{ analtyic.Stat }</h5>
                                </div>
                            )}
                            <svg className="impact-background" viewBox="0 0 1069 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_278_572)">
                                <mask id="path-1-inside-1_278_572" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M103.694 7.93066H1022.03L1055.35 7.93067L1064.65 17.327L1064.66 63.8517L1064.65 63.8437V104.604L1055.35 114L1022.03 114L968.892 114L103.694 114H39.6523H17.2395L7.93164 104.604V17.327L17.2395 7.93066H39.6523H103.694Z"/>
                                </mask>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M103.694 7.93066H1022.03L1055.35 7.93067L1064.65 17.327L1064.66 63.8517L1064.65 63.8437V104.604L1055.35 114L1022.03 114L968.892 114L103.694 114H39.6523H17.2395L7.93164 104.604V17.327L17.2395 7.93066H39.6523H103.694Z" fill="#5A3230"/>
                                <path d="M1022.03 7.93066L1022.03 6.93066H1022.03V7.93066ZM1055.35 7.93067L1056.06 7.22692L1055.76 6.93067L1055.35 6.93067L1055.35 7.93067ZM1064.65 17.327L1065.65 17.3268L1065.65 16.9155L1065.36 16.6233L1064.65 17.327ZM1064.66 63.8517L1063.95 64.5556L1065.66 66.2819L1065.66 63.8515L1064.66 63.8517ZM1064.65 63.8437L1065.36 63.1398L1063.65 61.4138V63.8437H1064.65ZM1064.65 104.604L1065.36 105.308L1065.65 105.015V104.604H1064.65ZM1055.35 114L1055.35 115L1055.76 115L1056.06 114.704L1055.35 114ZM1022.03 114L1022.03 113L1022.03 113L1022.03 114ZM968.892 114L968.892 115L968.892 115L968.892 114ZM103.694 114V113V114ZM17.2395 114L16.529 114.704L16.8225 115H17.2395V114ZM7.93164 104.604H6.93164V105.015L7.2212 105.308L7.93164 104.604ZM7.93164 17.327L7.2212 16.6232L6.93164 16.9156V17.327H7.93164ZM17.2395 7.93066V6.93066H16.8225L16.529 7.22691L17.2395 7.93066ZM103.694 8.93066H1022.03V6.93066H103.694V8.93066ZM1022.03 8.93066L1055.35 8.93067L1055.35 6.93067L1022.03 6.93066L1022.03 8.93066ZM1054.64 8.63442L1063.94 18.0308L1065.36 16.6233L1056.06 7.22692L1054.64 8.63442ZM1063.65 17.3272L1063.66 63.8518L1065.66 63.8515L1065.65 17.3268L1063.65 17.3272ZM1065.37 63.1478L1065.36 63.1398L1063.94 64.5476L1063.95 64.5556L1065.37 63.1478ZM1063.65 63.8437V104.604H1065.65V63.8437H1063.65ZM1063.94 103.9L1054.64 113.296L1056.06 114.704L1065.36 105.308L1063.94 103.9ZM1055.35 113L1022.03 113L1022.03 115L1055.35 115L1055.35 113ZM1022.03 113L968.892 113L968.892 115L1022.03 115L1022.03 113ZM968.892 113L103.694 113L103.694 115L968.892 115L968.892 113ZM103.694 113H39.6523V115H103.694V113ZM39.6523 113H17.2395V115H39.6523V113ZM17.9499 113.296L8.64209 103.9L7.2212 105.308L16.529 114.704L17.9499 113.296ZM8.93164 104.604V17.327H6.93164V104.604H8.93164ZM8.64209 18.0308L17.9499 8.63442L16.529 7.22691L7.2212 16.6232L8.64209 18.0308ZM17.2395 8.93066H39.6523V6.93066H17.2395V8.93066ZM39.6523 8.93066H103.694V6.93066H39.6523V8.93066Z" fill="#F9C12E" mask="url(#path-1-inside-1_278_572)"/>
                                </g>
                                <mask id="path-3-inside-2_278_572" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M95.7625 0H960.96H1014.1H1047.41L1056.72 9.39633L1056.73 55.921L1056.72 55.913V96.6732L1047.41 106.07H1014.1H960.96H95.7625H31.7207H9.30781L0 96.6732V9.39633L9.30781 0H31.7207H95.7625Z"/>
                                </mask>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M95.7625 0H960.96H1014.1H1047.41L1056.72 9.39633L1056.73 55.921L1056.72 55.913V96.6732L1047.41 106.07H1014.1H960.96H95.7625H31.7207H9.30781L0 96.6732V9.39633L9.30781 0H31.7207H95.7625Z" fill="#5A3230"/>
                                <path d="M1047.41 0L1048.13 -0.70375L1047.83 -1H1047.41V0ZM1056.72 9.39633L1057.72 9.39616L1057.72 8.98482L1057.43 8.69259L1056.72 9.39633ZM1056.73 55.921L1056.02 56.6249L1057.73 58.3512L1057.73 55.9208L1056.73 55.921ZM1056.72 55.913L1057.43 55.2091L1055.72 53.4832V55.913H1056.72ZM1056.72 96.6732L1057.43 97.377L1057.72 97.0847V96.6732H1056.72ZM1047.41 106.07V107.07H1047.83L1048.13 106.773L1047.41 106.07ZM9.30781 106.07L8.59737 106.773L8.89083 107.07H9.30781V106.07ZM0 96.6732H-1V97.0847L-0.710445 97.377L0 96.6732ZM0 9.39633L-0.710445 8.69258L-1 8.98489V9.39633H0ZM9.30781 0V-1H8.89083L8.59737 -0.703752L9.30781 0ZM95.7625 1H960.96V-1H95.7625V1ZM960.96 1H1014.1V-1H960.96V1ZM1014.1 1H1047.41V-1H1014.1V1ZM1046.7 0.70375L1056.01 10.1001L1057.43 8.69259L1048.13 -0.70375L1046.7 0.70375ZM1055.72 9.39651L1055.73 55.9212L1057.73 55.9208L1057.72 9.39616L1055.72 9.39651ZM1057.44 55.2171L1057.43 55.2091L1056.01 56.6169L1056.02 56.6249L1057.44 55.2171ZM1055.72 55.913V96.6732H1057.72V55.913H1055.72ZM1056.01 95.9695L1046.7 105.366L1048.13 106.773L1057.43 97.377L1056.01 95.9695ZM1047.41 105.07H1014.1V107.07H1047.41V105.07ZM1014.1 105.07H960.96V107.07H1014.1V105.07ZM960.96 105.07H95.7625V107.07H960.96V105.07ZM95.7625 105.07H31.7207V107.07H95.7625V105.07ZM31.7207 105.07H9.30781V107.07H31.7207V105.07ZM10.0183 105.366L0.710445 95.9695L-0.710445 97.377L8.59737 106.773L10.0183 105.366ZM1 96.6732V9.39633H-1V96.6732H1ZM0.710445 10.1001L10.0183 0.703752L8.59737 -0.703752L-0.710445 8.69258L0.710445 10.1001ZM9.30781 1H31.7207V-1H9.30781V1ZM31.7207 1H95.7625V-1H31.7207V1Z" fill="#F9C12E" mask="url(#path-3-inside-2_278_572)"/>
                                <defs>
                                <filter id="filter0_d_278_572" x="3.93164" y="7.93066" width="1064.73" height="auto" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="4"/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feComposite in2="hardAlpha" operator="out"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_278_572"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_278_572" result="shape"/>
                                </filter>
                                </defs>
                            </svg>                         
                        </div>
                        <img className="impact-image" src={ `${ section.Image.data.attributes.url }`} />
                    </div>
                </div>
                
            </div>
        );
    }
    
    export default CaseStudyImpact;
