import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

import useFetch from '../useFetch';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

// === COMPONENTS

    function CauseTeam() {
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/our-team?populate=deep`)

        let members = null;

        if (data) {

            members =data.data.attributes.team_members.data

            return (
    
                <div className="main-content-wrapper">
                    <h2 className="section-title">Our Team</h2>
                    <div className="team-container our-team-wrapper">
                        {members.map((member, index) => 
                            <div className="team-wrapper">
                                <div className="team-image-tag" id={`team-headshot-${index % 3}`} style={{backgroundColor: `${member.attributes.Primary_Color}`}} > 
                                    <img className="headshot" src={ `${ member.attributes.Headshot.data.attributes.url }`} />
                                    <div className="hexigon-body-orange">
                                        <div style={{backgroundImage: `linear-gradient(45deg, ${member.attributes.Primary_Color} 50%, var(--primary-util) 50%)`}} className="tl-br-diagonal-orange-inverse"></div>
                                        <div style={{backgroundColor: `${member.attributes.Primary_Color}`}} className="hexagon-middle"></div>
                                        <div style={{backgroundImage: `linear-gradient(135deg, ${member.attributes.Primary_Color} 50%, var(--primary-util) 50%)`}} className="bl-tr-diagonal-orange-inverse"></div>
                                    </div>
                                </div>
                                <div className="team-desc-tag" style={{backgroundColor: `${member.attributes.Primary_Color}`}} id={`team-description-${index % 3}`}>
                                    <div className="hexigon-body-orange">
                                        <div style={{backgroundImage: `linear-gradient(135deg, var(--primary-util) 50%, ${member.attributes.Primary_Color} 50%)`}} className="bl-tr-diagonal"></div>
                                        <div style={{backgroundColor: `${member.attributes.Primary_Color}`}} className="hexagon-middle"></div>
                                        <div style={{backgroundImage: `linear-gradient(45deg, var(--primary-util) 50%, ${member.attributes.Primary_Color} 50%)`}} className="tl-br-diagonal"></div>
                                    </div>
                                    <div className="team-desc-text">
                                        <h2 style={{color: `${member.attributes.Secondary_Color}`}}>{ member.attributes.Name }</h2>
                                        <h4>{ member.attributes.Title }</h4>
                                        <BlocksRenderer content={ member.attributes.Biography } />
                                        <p className="easter-egg">{ member.attributes.Fun_Fact }</p>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            );
        }
          
    }
    
    export default CauseTeam;