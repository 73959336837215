
import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
    
    function CaseStudyParnters( props ) {

        let section = props.data;
        let partners = section.project_partners.data
        console.log(partners)
        return (        
            <div className="main-content-wrapper">
                <div className="case-study-info">
                    <h2>{ section.Section_Title }</h2>
                    <BlocksRenderer content={ section.Section_Description } />
                </div>
                <div className="partner-container">
                    <div className="partner-wrapper"> 
                        {partners.map((partner) => 
                            <div className="partner-card">
                            { partner.attributes.External_Link ? (
                                <a href={partner.attributes.External_Link} className="partner-card">
                                    <h1 className="partner-name">{partner.attributes.Name}</h1>
                                    <div className="square"></div>
                                </a>
                            ) : (
                            <div className="partner-card">
                                <h1 className="partner-name">{partner.attributes.Name}</h1>
                                <div className="square"></div>
                            </div>
                            )}
                        </div>
                        )}
                        <h2 className="many-more">and more.</h2>
                    </div>
                </div>
            </div>
        );
    }
    
    export default CaseStudyParnters;