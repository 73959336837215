import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

import CauseTeam from '../components/causeTeam';
import CreativePartners from '../components/creativePartners';

// === COMPONENTS

    function OurTeam() {
        

          return (
            
            <div className="page-container">
                <div className="side-controller">
                    <svg className="cause-stacked" viewBox="0 0 277 838" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M129.664 68.6497V118.695L118.178 130.232H11.4865L0 118.695V11.5368L11.4865 0H118.178L129.664 11.5368V61.5819H68.3505V32.5628C68.3505 31.5686 68.0075 30.7024 67.3312 29.9543C66.655 29.2061 65.8219 28.901 64.832 29.029C63.8422 29.029 63.0091 29.403 62.3328 30.1413C61.6566 30.8894 61.3136 31.7556 61.3136 32.7499V97.4916C61.3136 98.4858 61.5586 99.3225 62.0584 100.002C62.5485 100.681 63.2933 101.085 64.2832 101.213C65.3907 101.459 66.3512 101.213 67.1548 100.464C67.9585 99.7163 68.3603 98.791 68.3603 97.6786V68.6595H129.674L129.664 68.6497Z" fill="#FEFEE4"/>
                        <path d="M276.07 119.253V69.2075L276.08 69.2173L276.07 12.0945L264.584 0.55777H157.893L146.406 12.0945V119.253L157.893 130.789H264.584L276.07 119.253Z" fill="#F9C12E"/>
                        <path d="M129.664 271.261V221.323L129.674 221.332L129.664 164.332L118.178 152.82H11.4865L0 164.332L0 271.261L11.4865 282.773H118.178L129.664 271.261Z" fill="#F9C12E"/>
                        <path d="M276.359 737.05H146.686V606.818H276.359V635.837H211.522C209.18 635.837 208.004 637.018 208.004 639.371C208.004 641.724 209.18 642.905 211.522 642.905H276.359V668.39H211.522C209.18 668.39 208.004 669.571 208.004 671.924C208.004 674.277 209.18 675.458 211.522 675.458H276.359V737.04V737.05Z" fill="#FEFEE4"/>
                        <path d="M276.35 427.985V378.047L276.359 378.057L276.35 321.056L264.863 309.544H158.172L146.686 321.056V427.985L158.172 439.497H264.863L276.35 427.985Z" fill="#F9C12E"/>
                        <path d="M146.406 282.205V164.536L157.819 153.099H263.83L275.244 164.536V282.205H214.321V249.934C214.321 248.948 214.019 248.118 213.406 247.445C212.792 246.772 212.052 246.43 211.195 246.43H210.825C209.841 246.43 209.014 246.801 208.342 247.533C207.67 248.275 207.329 249.133 207.329 250.119V282.215H146.406V282.205ZM208.342 204.01C209.014 204.751 209.841 205.112 210.825 205.112C211.808 205.112 212.636 204.741 213.308 204.01C213.98 203.268 214.321 202.409 214.321 201.423V185.566C214.321 184.58 213.98 183.721 213.308 182.98C212.636 182.238 211.808 181.877 210.825 181.877H210.455C209.598 181.877 208.858 182.219 208.244 182.892C207.631 183.565 207.329 184.395 207.329 185.38V201.423C207.329 202.409 207.67 203.268 208.342 204.01Z" fill="#FEFEE4"/>
                        <path d="M129.674 501.628V572.694L118.187 584.23H0V555.213H64.8369C65.8269 555.213 66.66 554.908 67.3363 554.287C68.0126 553.667 68.3557 552.919 68.3557 552.053V551.679C68.3557 550.685 67.9832 549.848 67.2481 549.169C66.513 548.49 65.6407 548.145 64.6507 548.145H11.4874L0 536.61V465.534L11.4874 453.998H129.674V483.015H65.0232C64.0332 483.015 63.1707 483.359 62.4257 484.039C61.6808 484.718 61.3182 485.554 61.3182 486.549V486.923C61.3182 487.789 61.6613 488.537 62.3376 489.157C63.0139 489.777 63.847 490.082 64.8369 490.082H118.187L129.674 501.618V501.628Z" fill="#FEFEE4"/>
                        <path d="M276.07 573.251V523.205L276.08 523.215L276.07 466.093L264.584 454.556H157.893L146.406 466.093V573.251L157.893 584.787H264.584L276.07 573.251Z" fill="#F9C12E"/>
                        <path d="M129.664 725.259V675.321L129.674 675.33L129.664 618.33L118.178 606.818H11.4865L0 618.33L0 725.259L11.4865 736.771H118.178L129.664 725.259Z" fill="#F9C12E"/>
                        <path d="M129.664 428.239L118.178 439.776H11.4865L0 428.239V309.544H61.3136V374.66C61.3136 375.772 61.7154 376.707 62.5191 377.446C63.3227 378.194 64.2832 378.44 65.3907 378.194C66.3806 378.066 67.1156 377.662 67.6154 376.983C68.1055 376.304 68.3603 375.467 68.3603 374.473V309.544H129.674V428.239H129.664Z" fill="#FEFEE4"/>
                        <path d="M80.9089 763.179L80.9146 796.548L80.9088 796.543V798.967V830.276L74.2363 837H7.6725L1 830.276V763.179L7.6725 756.455H74.2363L80.9089 763.179Z" stroke="#FEFEE4" stroke-width="2"/>
                        <path d="M6.93164 817.66V793.262H17.652V817.569C17.652 818.204 18.2065 818.657 18.761 818.657H76.2443V829.178H18.5762C12.1994 829.178 6.93164 823.918 6.93164 817.66Z" fill="#FEFEE4"/>
                        <path d="M174.165 763.179L174.17 796.548L174.165 796.543V798.967V830.276L167.492 837H100.928L94.2559 830.276V763.179L100.928 756.455H167.492L174.165 763.179Z" stroke="#FEFEE4" stroke-width="2"/>
                        <path d="M110.753 794.522H157.673C163.715 794.522 168.869 799.598 168.869 805.637V829.178H158.561V817.976C158.561 817.364 158.028 816.926 157.495 816.926H110.931C110.398 816.926 109.865 817.364 109.865 817.976V829.178H99.5566V805.637C99.5566 799.598 104.711 794.522 110.753 794.522ZM109.776 805.724C109.776 806.337 110.309 806.774 110.842 806.774H157.584C158.117 806.774 158.65 806.337 158.65 805.724C158.65 805.112 158.117 804.674 157.584 804.674H110.842C110.309 804.674 109.776 805.112 109.776 805.724Z" fill="#FEFEE4"/>
                        <path d="M273.092 763.179L273.098 796.548L273.092 796.543V798.967V830.276L266.42 837H199.856L193.184 830.276V763.179L199.856 756.455H266.42L273.092 763.179Z" stroke="#FEFEE4" stroke-width="2"/>
                        <path d="M267.167 805.637V805.899C267.167 808.087 266.551 810.187 265.406 811.938C266.551 813.688 267.167 815.701 267.167 817.889V818.151C267.167 824.278 262.236 829.178 256.072 829.178H198.484V794.522H256.072C262.148 794.522 267.167 799.598 267.167 805.637ZM255.984 804.674H208.699V806.774H255.984C256.512 806.774 257.041 806.337 257.041 805.724C257.041 805.112 256.512 804.674 255.984 804.674ZM208.699 819.027H255.984C256.512 819.027 257.041 818.589 257.041 817.976C257.041 817.364 256.512 816.926 255.984 816.926H208.699V819.027Z" fill="#FEFEE4"/>
                    </svg>
                </div>
                <CauseTeam />
                <CreativePartners />
            </div>
        );
    }
    
    export default OurTeam;