
import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
    
function CaseStudyVideo( props ) {

    let section = props.data

    console.log(section)
    return (        
        <div className="main-content-wrapper">
            <div className="case-study-info">
                <h2>{ section.Section_Title }</h2>
                <BlocksRenderer content={ section.Section_Description } />
            </div>
            <div className="gallery-video-container">
                <svg viewBox="0 0 1336 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_278_555)">
                    <mask id="path-1-inside-1_278_555" fill="white">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1352 45.9999V-195.5V-460.5V-702L187.244 -702L40.6663 -702L-5.99998 -657.134L-6 -415.596V-240.404L-5.99998 1.13428L40.6663 46L187.244 46L1352 45.9999Z"/>
                    </mask>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1352 45.9999V-195.5V-460.5V-702L187.244 -702L40.6663 -702L-5.99998 -657.134L-6 -415.596V-240.404L-5.99998 1.13428L40.6663 46L187.244 46L1352 45.9999Z" fill="#FEFEE4"/>
                    <path d="M1352 45.9999H1357V50.9999L1352 50.9999L1352 45.9999ZM1352 -702L1352 -707L1357 -707V-702H1352ZM187.244 -702L187.244 -707L187.244 -707L187.244 -702ZM40.6663 -702L37.201 -705.604L38.6526 -707L40.6663 -707L40.6663 -702ZM-5.99998 -657.134L-11 -657.134L-11 -659.263L-9.46529 -660.739L-5.99998 -657.134ZM-6 -415.596L-11 -415.596L-11 -415.596L-6 -415.596ZM-6 -240.404L-11 -240.404V-240.404H-6ZM-5.99998 1.13428L-9.4653 4.73866L-11 3.26319L-11 1.13428L-5.99998 1.13428ZM40.6663 46L40.6663 51L38.6526 51L37.201 49.6044L40.6663 46ZM187.244 46L187.244 51L187.244 51L187.244 46ZM1357 -195.5V45.9999H1347V-195.5H1357ZM1347 -195.5V-460.5H1357V-195.5H1347ZM1347 -460.5V-702H1357V-460.5H1347ZM1352 -697L187.244 -697L187.244 -707L1352 -707L1352 -697ZM187.244 -697L40.6663 -697L40.6663 -707L187.244 -707L187.244 -697ZM44.1316 -698.396L-2.53467 -653.53L-9.46529 -660.739L37.201 -705.604L44.1316 -698.396ZM-0.999982 -657.134L-1 -415.596L-11 -415.596L-11 -657.134L-0.999982 -657.134ZM-11 -240.404V-415.596H-1V-240.404H-11ZM-11 1.13428L-11 -240.404L-1 -240.404L-0.999982 1.13428L-11 1.13428ZM37.201 49.6044L-9.4653 4.73866L-2.53467 -2.47011L44.1316 42.3956L37.201 49.6044ZM187.244 51L40.6663 51L40.6663 41L187.244 41L187.244 51ZM1352 50.9999L187.244 51L187.244 41L1352 40.9999L1352 50.9999Z" fill="#E5521C" mask="url(#path-1-inside-1_278_555)"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_278_555">
                    <rect width="1336" height="46" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                <video controls id="vid" autoplay="true" playsInline="true" muted className="gallery-video">
                    <source type="video/mp4" src={`${section.Video.data.attributes.url}`} />
                </video>
                <svg width="1336" height="46" viewBox="0 0 1336 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_278_556)">
                    <mask id="path-1-inside-1_278_556" fill="white">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1352 748V506.5V241.5V-5.09131e-05L187.244 -0.000111948L40.6663 -5.09131e-05L-5.99998 44.8656L-6 286.404V461.596L-5.99998 703.134L40.6663 748L187.244 748L1352 748Z"/>
                    </mask>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1352 748V506.5V241.5V-5.09131e-05L187.244 -0.000111948L40.6663 -5.09131e-05L-5.99998 44.8656L-6 286.404V461.596L-5.99998 703.134L40.6663 748L187.244 748L1352 748Z" fill="#FEFEE4"/>
                    <path d="M1352 748H1357V753L1352 753L1352 748ZM1352 -5.09131e-05L1352 -5.00005L1357 -5.00005V-5.09131e-05H1352ZM187.244 -0.000111948L187.244 -5.00011L187.244 -5.00011L187.244 -0.000111948ZM40.6663 -5.09131e-05L37.201 -3.60444L38.6526 -5.00005L40.6663 -5.00005L40.6663 -5.09131e-05ZM-5.99998 44.8656L-11 44.8656L-11 42.7367L-9.46529 41.2612L-5.99998 44.8656ZM-6 286.404L-11 286.404L-11 286.404L-6 286.404ZM-6 461.596L-11 461.596V461.596H-6ZM-5.99998 703.134L-9.4653 706.739L-11 705.263L-11 703.134L-5.99998 703.134ZM40.6663 748L40.6663 753L38.6526 753L37.201 751.604L40.6663 748ZM187.244 748L187.244 753L187.244 753L187.244 748ZM1357 506.5V748H1347V506.5H1357ZM1347 506.5V241.5H1357V506.5H1347ZM1347 241.5V-5.09131e-05H1357V241.5H1347ZM1352 4.99995L187.244 4.99989L187.244 -5.00011L1352 -5.00005L1352 4.99995ZM187.244 4.99989L40.6663 4.99995L40.6663 -5.00005L187.244 -5.00011L187.244 4.99989ZM44.1316 3.60434L-2.53467 48.47L-9.46529 41.2612L37.201 -3.60444L44.1316 3.60434ZM-0.999982 44.8656L-1 286.404L-11 286.404L-11 44.8656L-0.999982 44.8656ZM-11 461.596V286.404H-1V461.596H-11ZM-11 703.134L-11 461.596L-1 461.596L-0.999982 703.134L-11 703.134ZM37.201 751.604L-9.4653 706.739L-2.53467 699.53L44.1316 744.396L37.201 751.604ZM187.244 753L40.6663 753L40.6663 743L187.244 743L187.244 753ZM1352 753L187.244 753L187.244 743L1352 743L1352 753Z" fill="#E5521C" mask="url(#path-1-inside-1_278_556)"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_278_556">
                    <rect width="1336" height="46" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    );
}
    
export default CaseStudyVideo;