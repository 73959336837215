import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link, useParams } from "react-router-dom";

// === COMPONENTS
import Services from '../components/services';
import ContactForm from '../components/contactForm';
import CaseStudyIntro from '../components/caseStudyIntro';
import CaseStudyGallery from '../components/caseStudyGallery';
import CaseStudyVideo from '../components/caseStudyVideo';
import CaseStudyParnters from '../components/caseStudyPartners';
import CaseStudyImpact from '../components/caseStudyImpact';
import useFetch from '../useFetch';
import CaseStudyFeaturedImage from '../components/caseStudyFeaturedImage';

    function CaseStudy() {
        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/case-studies?filters[slug][$eq]=${id}&populate[Sections][populate]=*`)
        
        let sections = null;
        let display = [];
        
        if (data) {

            sections = data.data[0].attributes.Sections

            for (let i = 0; i < sections.length; i ++) {
                if (sections[i].__component === "case-study.featured-image") {
                    display.push(<CaseStudyFeaturedImage data={id} />)
                } else if (sections[i].__component === "case-study.gallery") {
                    display.push(<CaseStudyGallery data={sections[i]} />)
                } else if (sections[i].__component === "case-study.video") {
                    display.push(<CaseStudyVideo data={sections[i]} />)
                } else if (sections[i].__component === "case-study.partners") {
                    display.push(<CaseStudyParnters data={sections[i]} />)
                } else if (sections[i].__component === "case-study.impact") {
                    display.push(<CaseStudyImpact data={sections[i]} />)
                }
            }

            return (
                <div className="page-container">
                    <CaseStudyIntro data={id} />
                    {display.map((section, index) => 
                        <div key={ index }>
                            { section }
                        </div>
                    )}
                </div>
            );
        }
    }
    
    export default CaseStudy;