import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams } from "react-router-dom";

import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import useFetch from '../useFetch';

// === Pages

    function CaseStudyFeaturedImage(props) {

        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/case-studies?filters[slug][$eq]=${id}&populate[Sections][populate]=*`)
        
        let caseStudy = null;
        let introSection = null;
        let impact = null;
        let sections = [];

        if (data) {

            caseStudy = data.data[0].attributes
            sections = data.data[0].attributes.Sections

            for (let i = 0; i < sections.length; i ++) {
                if (sections[i].__component === "case-study.featured-image") {
                    introSection = sections[i]
                } else if (sections[i].__component === "case-study.impact") {
                    impact = sections[i]
                } 
            }

            return (
                <div className="main-content-wrapper">
                    <div className="case-study-info">
                        <h2>{ introSection.Section_Title }</h2>
                        <BlocksRenderer content={ introSection.Section_Description } />
                    </div>
                    <div className="case-study-wrapper">
                
                        <img className="intro-case-study-image" src={ `${ introSection.Image.data.attributes.url }`} />
                    </div>
                    
                </div>
            );
        }
        
    }
    
    export default CaseStudyFeaturedImage;
