import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

import useFetch from '../useFetch';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

// === COMPONENTS

    function CreativePartners() {
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/our-team?populate=deep`)
        
        let members = null;

        if (data) {
            
            members =data.data.attributes.creative_partners.data

            return (
    
                <div className="main-content-wrapper">
                    <h2 className="section-title">Creative Partners</h2>
                    <div className="creative-partners-wrapper">
                        {members.map((member, index) => 
                            <div className="creative-partner-card">
                                <div >
                                    <img className="partner-headshot" src={`${member.attributes.Headshot.data.attributes.url}`}/>
                                    <div className="creative-partner-description">
                                        <h2>{ member.attributes.Name }</h2>
                                        <h4>{ member.attributes.Title }</h4>
                                        <BlocksRenderer content={ member.attributes.Biography } />
                                    </div>
                                </div>
                                <div className="hexigon-body-cp">
                                    <div className="tl-br-diagonal-cp"></div>
                                    <div className="hexigon-middle-cp"></div>
                                    <div className="bl-tr-diagonal-cp"></div>
                                </div>
                            </div>
                        )};
                    </div>
                </div>
            );
        }
    }
    
    export default CreativePartners;