import { useEffect, useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";

// === COMPONENTS
import C from "../photos/C-Cause.svg";
import A from "../photos/A-Cause.svg";
import U from "../photos/U-Cause.svg";
import S from "../photos/S-Cause.svg";
import E from "../photos/E-Cause.svg";
import useFetch from '../useFetch';

    function CaseStudyList() {
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/case-study-list?populate=deep`)
        const [currentProject, setCurrentProject] = useState('none')

        let caseStudies = []

        const imageToggleOn = (id) => {
            document.getElementById('image-bckground').classList.remove("fadeOut")
            document.getElementById('image-bckground').classList.add("fadeIn")
            setCurrentProject(id)
        }

        const imageToggleOff = () => {
            document.getElementById('image-bckground').classList.remove("fadeIn")
            document.getElementById('image-bckground').style.opacity = 1;
            document.getElementById('image-bckground').classList.add("fadeOut")
        }

        if (data) {
            
            caseStudies = data.data.attributes.case_studies.data

            console.log (`case`, caseStudies)

            return (
                <div className="main-content-wrapper-work-list">
                    {/* <div className="image-handler" style={{backgroundImage: `url(${ currentProject })`}}> */}
                    <div className="image-handler">
                        <img className="image-handler-background" id="image-bckground" src={ currentProject } />
                        <h2>Our Work</h2>
                    </div>
                    <div className="case-study-list-container" >
                        <img className="grid-element fadeIn" src={ C } />
                        { caseStudies[0] ? (
                            <a 
                                href={`/our-work/${caseStudies[0].attributes.slug}`}
                                onMouseEnter={() => imageToggleOn(`${caseStudies[0].attributes.Feature_Image.data.attributes.url}`)}
                                onMouseLeave={() => imageToggleOff()}
                                className="grid-element">
                                <h2>{caseStudies[0].attributes.Project_Title}</h2>
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </a>
                        ) : (
                            <div className="grid-element">
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </div>
                            ) 
                        }
                        { caseStudies[1] ? (
                            <a 
                                href={`/our-work/${caseStudies[1].attributes.slug}`}
                                onMouseEnter={() => imageToggleOn(`${caseStudies[1].attributes.Feature_Image.data.attributes.url}`)}
                                onMouseLeave={() => imageToggleOff()}
                                className="grid-element">
                                <h2>{caseStudies[1].attributes.Project_Title}</h2>
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </a>
                        ) : (
                            <div className="grid-element">
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </div>
                            ) 
                        }
                        <img className="grid-element" src={ A } />
                        <img className="grid-element" src={ U } />
                        { caseStudies[2] ? (
                            <a 
                                href={`/our-work/${caseStudies[2].attributes.slug}`}
                                onMouseEnter={() => imageToggleOn(`${caseStudies[2].attributes.Feature_Image.data.attributes.url}`)}
                                onMouseLeave={() => imageToggleOff()}
                                className="grid-element">
                                <h2>{caseStudies[2].attributes.Project_Title}</h2>
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </a>
                        ) : (
                            <div className="grid-element">
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </div>
                            ) 
                        }
                        <img className="grid-element" src={ S } />
                        { caseStudies[3] ? (
                            <a 
                                href={`/our-work/${caseStudies[3].attributes.slug}`}
                                onMouseEnter={() => imageToggleOn(`${caseStudies[3].attributes.Feature_Image.data.attributes.url}`)}
                                onMouseLeave={() => imageToggleOff()}
                                className="grid-element">
                                <h2>{caseStudies[3].attributes.Project_Title}</h2>
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </a>
                        ) : (
                            <div className="grid-element">
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </div>
                            ) 
                        }
                        { caseStudies[4] ? (
                            <a 
                                href={`/our-work/${caseStudies[4].attributes.slug}`}
                                onMouseEnter={() => imageToggleOn(`${caseStudies[4].attributes.Feature_Image.data.attributes.url}`)}
                                onMouseLeave={() => imageToggleOff()}
                                className="grid-element">
                                <h2>{caseStudies[4].attributes.Project_Title}</h2>
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </a>
                        ) : (
                            <div className="grid-element">
                                <svg className="grid-hex" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 465 466">
                                    <path d="M464.965 424.719V245.645L465 245.68L464.965 41.2813L423.775 0L41.1896 0L0 41.2813L0 424.719L41.1896 466H423.775L464.965 424.719Z"/>
                                </svg>
                            </div>
                            ) 
                        }
                        <img className="grid-element" src={ E } />
                    </div>
                </div>
            );
        }
    }
    
    export default CaseStudyList;